import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { useMedia } from 'react-media';
import { Dropdown } from 'semantic-ui-react';
import { useDesignConfig } from '../config/design.context';
import { GLOBAL_MEDIA_QUERIES } from '../utils/mediaQueries';
import ExpiringNavLink from './ExpiringNavLink';
import EntitySearchBar from './SearchBar/EntitySearchBar';
import CdnImage from './CdnImage';

const { lang } = window.__DATA__;

export function makeLanguagePage(newLang) {
  const { basename } = window.__DATA__;
  const curBasename = trim(basename, '/'); // Trim to be tolerant to missing slashes
  const newBasename = curBasename.replace(lang, newLang);
  return window.location.href.replace(`/${curBasename}`, `/${newBasename}`);
}

export function handleChangeLanguage(_e, { value }) {
  window.location = makeLanguagePage(value);
}

const AppHeader = ({ language, languageOptions }) => {
  const { logo, logoProps = {}, logoSecondary, logoSecondaryHeight = 100 } = useDesignConfig();
  const { logoPath = '/', altText = '' } = logoProps;
  const { desktop } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  if (!logo) return null;

  const hasMultipleLanguages = languageOptions && languageOptions.length > 1;
  return (
    <div className="menu--header">
      <ExpiringNavLink to={logoPath}>
        <CdnImage
          as="img"
          className="logo--main"
          src={logo}
          maxHeight={100}
          alt={altText}
          {...logoProps}
        />
      </ExpiringNavLink>
      {logoSecondary && (
        <CdnImage
          as="img"
          src={logoSecondary}
          className="logo--secondary"
          maxHeight={logoSecondaryHeight}
          alt=""
          {...logoProps}
        />
      )}
      {desktop && (
        <EntitySearchBar
          className={cx('menu--header__search--bar', {
            'menu--header__search--bar-shrink': hasMultipleLanguages,
          })}
        />
      )}
      {hasMultipleLanguages && (
        <Dropdown
          className="dropdown--languages"
          placeholder="Language"
          compact
          selection
          options={languageOptions}
          value={language}
          onChange={handleChangeLanguage}
        />
      )}
    </div>
  );
};

AppHeader.defaultProps = {
  language: 'fr',
  languageOptions: undefined,
};

AppHeader.propTypes = {
  language: PropTypes.string,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
};

export default AppHeader;
