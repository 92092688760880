import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Message, Segment } from 'semantic-ui-react';
import UserRegistrationModal from '../../authentication/components/UserRegistrationModal/UserRegistrationModal';
import { DateTimeDisplay } from '../../components/DateTimeDisplay';
import QuotaField from '../../components/workshops/QuotaField';
import { useIsAnonymous } from '../../profile/hooks';
import { useWorkshopSessionContext } from '../../workshop-session/workshopSession.context';
import { workshopPropType } from '../utils';
import { useWorkshopRegistration } from '../utils/workshopRegistrationUtils';
import WorkshopActionsBlock from './WorkshopActionsBlock';
import './WorkshopRegistrationBlock.scss';

const translationPrefix = 'workshops.workshop';
const WorkshopRegistrationBlock = ({ actions, item: workshop, registration }) => {
  const { t } = useTranslation();
  const { constraints } = useWorkshopSessionContext();
  const {
    isWorkshopFull,
    isWorkshopEnded,
    loading,
    registrationState,
    // actions,
    registerWorkshop,
    unregisterWorkshop,
  } = useWorkshopRegistration(workshop, registration, { constraints });
  const isAnonymous = useIsAnonymous();
  const [isOpen, setIsOpen] = useState(false);
  const { startDate, endDate, mandatory, quota, usersCount } = workshop;

  if (isWorkshopEnded) {
    return (
      <Segment className="workshop--registrationBlock">
        <Message>
          <Message.Header>{t(`${translationPrefix}.workshop-ended`)}</Message.Header>
        </Message>
      </Segment>
    );
  }

  if (registration || mandatory) {
    // Already registered...
    return (
      <Segment className="workshop--registrationBlock">
        <Message>
          <Message.Header>
            <DateTimeDisplay startDate={startDate} endDate={endDate} />
          </Message.Header>
          {!mandatory && <p>{t(`${translationPrefix}.already-registered`)}</p>}
          {mandatory && (
            <p style={{ textAlign: 'justify', fontWeight: 'bold', color: 'red' }}>
              {t(`${translationPrefix}.mandatory-registered`)}
            </p>
          )}
          <WorkshopActionsBlock
            actions={actions}
            loading={loading}
            mandatory={mandatory}
            unregisterWorkshop={unregisterWorkshop}
            workshop={workshop}
          />
        </Message>
      </Segment>
    );
  }

  function renderMessage() {
    if (!workshop.startDate) {
      return (
        <Message>
          <p>{t(`${translationPrefix}.registrations-not-yet-open`)}</p>
        </Message>
      );
    }
    const header = (
      <Message.Header>
        <DateTimeDisplay startDate={startDate} endDate={endDate} />
      </Message.Header>
    );
    if (!registrationState.registrationOpen) {
      const messageKey = registrationState.state.toLowerCase().replace(/_/g, '-');
      return (
        <Message>
          {header}
          {isWorkshopFull && (
            <p className="registration-unavailable full-workshop-label">
              {t(`${translationPrefix}.workshop-full`)}
            </p>
          )}
          {!isWorkshopFull && (
            <p className={`registration-unavailable registration-unavailable--${messageKey}`}>
              {t(`workshops.messages.${messageKey}`, {
                data: registrationState.metadata,
                defaultValue: t('workshops.messages.not-available'),
              })}
            </p>
          )}
        </Message>
      );
    }
    return (
      <Message>
        {header}
        <QuotaField quota={quota} usersCount={usersCount} />
        <p className="interested-by-workshop-label">
          {t(`${translationPrefix}.interested-by-this-workshop`)}
        </p>

        <Button
          className="registration-button"
          primary
          onClick={() => (isAnonymous ? setIsOpen(true) : registerWorkshop())}
          loading={loading}
          disabled={loading}
          style={{ width: '100%' }}
        >
          {t(`${translationPrefix}.register`)}
        </Button>
      </Message>
    );
  }

  return (
    <Segment className="workshop--registrationBlock">
      {renderMessage()}
      {isOpen && <UserRegistrationModal onClose={() => setIsOpen(false)} />}
    </Segment>
  );
};

WorkshopRegistrationBlock.defaultProps = {
  registration: undefined,
};

WorkshopRegistrationBlock.propTypes = {
  registration: PropTypes.object, // current registration, or undefined
  item: workshopPropType.isRequired,
};

export default connect((state, { item }) => {
  const isSession = !!item.workshopId;
  const { registrationsById, registrationsBySessionId } = state.registrations;
  if (isSession) return { registration: registrationsBySessionId[item._id] };
  return { registration: registrationsById[item._id] };
})(WorkshopRegistrationBlock);
