/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import CdnImage from '../CdnImage';
import './VideoCard.scss';
import VideoModal from './VideoModal';

const VideoCard = ({ onClick, video, item, style, videoProps, statsMode }) => {
  const [showModal, setShowModal] = useState(false);
  const { title, value = {}, file = {}, thumbnail } = video;
  const { trackEvent } = useTracking();

  const videoTitle = title || value?.originalFilename || file?.originalFilename;
  const downloadUri = value.downloadUri || file.downloadUri;
  const originalFilename =
    video?.originalFilename || file?.originalFilename || video?.value?.originalFilename;

  function onDownload(e) {
    e.stopPropagation();
    trackEvent(eventTags.VIDEO_DOWNLOAD, {
      video,
      videoTitle,
      videoUrl: downloadUri,
      originalFilename,
    });
  }

  function handleClick() {
    if (onClick) {
      onClick(video);
    } else {
      setShowModal(true);
    }
  }
  return (
    <>
      <Card className="card--video" style={style} onClick={handleClick}>
        <div className="ui embed">
          <Icon name="video play" />
          {thumbnail?.uri && (
            <CdnImage className="placeholder" wrapped src={thumbnail} as="img" maxWidth={400} />
          )}
        </div>
        <Card.Content className="card--video-info">
          {videoTitle && <div className="card--video-title u-singleline ">{videoTitle}</div>}
          {downloadUri && (
            <Button
              circular
              basic
              icon="download"
              color="blue"
              as="a"
              target="_blank"
              href={downloadUri}
              onClick={onDownload}
              size="mini"
            />
          )}
        </Card.Content>
      </Card>

      {showModal && (value?.uri || file?.uri) && (
        <VideoModal
          video={video}
          videoProps={videoProps}
          statsMode={statsMode}
          item={item}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
VideoCard.defaultProps = {
  item: undefined,
  onClick: undefined,
  style: undefined,
  videoProps: undefined,
  statsMode: undefined,
};
VideoCard.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  item: PropTypes.object,
  video: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    originalFilename: PropTypes.string,
    value: PropTypes.shape({ uri: PropTypes.string.isRequired }),
    file: PropTypes.shape({ uri: PropTypes.string.isRequired }),
    thumbnail: PropTypes.shape({ uri: PropTypes.string.isRequired }),
  }).isRequired,
  videoProps: PropTypes.object,
  statsMode: PropTypes.string,
};

export default VideoCard;
