import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { containerTypePropTypes } from '../../propTypes';
import BlockHeader from '../BlockHeader';
import './BlockContainer.scss';

export const HtmlHeader = ({ content, ...props }) => {
  if (!content) return null;
  if (content.indexOf('</') !== -1) {
    // Oops, html
    return (
      <Header {...props}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </Header>
    );
  }
  return <Header {...props} content={content} />;
};

HtmlHeader.defaultProps = {
  content: undefined,
};

HtmlHeader.propTypes = {
  content: PropTypes.string,
};

const BlockContainer = ({ className, childWrapperClassName, type, header, children }) => {
  const containerClassName = cx(`block-container block-container--${type}`, className);

  if (type === 'segment') {
    return (
      <Segment.Group className={containerClassName}>
        {header && (
          <Segment className="segment--header">
            <HtmlHeader as="h3" content={header} />
            {/* TODO: Pourquoi h3 ? */}
          </Segment>
        )}
        <Segment className={cx('segment--content', childWrapperClassName)}>{children}</Segment>
      </Segment.Group>
    );
  }
  if (type === 'segment-light') {
    return (
      <Segment className={containerClassName}>
        {/* TODO: Pourquoi h3 ? */}
        {header && <HtmlHeader as="h3" content={header} />}
        {children}
      </Segment>
    );
  }
  if (type === 'basic') {
    return (
      <div className={containerClassName}>
        {header && <BlockHeader title={header} />}
        {children}
      </div>
    );
  }
  if (type === 'section')
    return (
      <div className={containerClassName}>
        {header && <BlockHeader title={header} />}
        {children}
      </div>
    );
  return <div className={containerClassName}>{children}</div>;
};

BlockContainer.defaultProps = {
  childWrapperClassName: undefined,
  className: undefined,
  header: undefined,
  type: 'segment',
};

BlockContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  childWrapperClassName: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string,
  type: containerTypePropTypes,
};

export const BlockContainerPropType = PropTypes.shape({
  childWrapperClassName: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string,
  type: containerTypePropTypes,
});

export default BlockContainer;
