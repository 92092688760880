import React from 'react';
import PropTypes from 'prop-types';
import { withDesign } from './config/design.context';
import { cssForFont } from './utils/fontUtils';
import Images from './utils/Images';

function alphaHex(alphaLevel) {
  const str = Math.floor(alphaLevel * 255).toString(16);
  if (str.length === 1) return `0${str}`;
  return str;
}

export function alpha(color, alphaLevel) {
  if (!color) return '';
  return color
    .replace(/rgb\((.*)\)/, (_all, group1) => `rgba(${group1}, ${alphaLevel})`)
    .replace(/rgba\((.*),[^,]+\)/, (_all, group1) => `rgba(${group1}, ${alphaLevel})`)
    .replace(/(#......)/, (_all, group1) => `${group1}${alphaHex(alphaLevel)}`);
}

const designPropType = PropTypes.shape({
  primaryColor: PropTypes.string,
  customCSS: PropTypes.string,
});

const Font = ({ type, font }) => {
  if (!font) return null;
  const css = cssForFont(type, font);
  if (!css) return null;
  return (
    <style
      key={`font-${type}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: css,
      }}
    />
  );
};

const fontFilePropType = PropTypes.shape({
  uri: PropTypes.string.isRequired,
  originalFilename: PropTypes.string,
});

Font.defaultProps = {
  font: undefined,
};

Font.propTypes = {
  font: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fallbacks: PropTypes.string,
    files: PropTypes.shape({
      normal: fontFilePropType,
      italic: fontFilePropType,
      bold: fontFilePropType,
      boldItalic: fontFilePropType,
    }),
  }),
  type: PropTypes.oneOf(['body']).isRequired,
};

class Styles extends React.PureComponent {
  render() {
    const { design, designOverride } = this.props;
    const { customCSS = '', fonts } = design;
    const primaryColor = designOverride?.primaryColor || design.primaryColor;
    return (
      <>
        {fonts && <Font type="body" font={fonts.body} />}
        <style
          key="colors-customCSS"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
/* Color styles */

a {
  color: ${primaryColor};
}

.ui.menu.menu--main:not(.secondary) .active.item
{
  color: ${primaryColor};
}

.ui.menu.menu--main.secondary:not(.pointing) .active.item
{
  background: ${primaryColor};
  color: white ;
}


.ui.primary.button, .ui.primary.buttons .button {
  background-color: ${primaryColor};
}

.ui.primary.basic.button {
  color: ${primaryColor}!important;
  box-shadow: 0 0 0 1px ${primaryColor} inset!important;
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover, .ui.primary.button:focus {
  background-color: ${alpha(primaryColor, 0.85)};
}

.ui.menu.menu--main.secondary.pointing .active.item
{
  border-color: ${primaryColor};
  color: ${primaryColor};
}

.BookAppointmentBlock--slots__slot.ui.button {
  border-color: ${primaryColor};
  background-color: ${alpha(primaryColor, 0.15)};
}

.BookAppointmentBlock--slots__slot.ui.button.active, .BookAppointmentBlock--slots__slot.ui.button:hover {
  background-color: ${primaryColor};
}

.toastify-progress-bar{
  background: ${primaryColor}!important;
}

h2.ui.header, h3.ui.header {
  color: ${primaryColor};
}

.workshop--category, .workshop--details__content--category {  
  color: ${primaryColor};
}

.ui.tiny.primary.button {
  background-color: ${primaryColor};
}

.ui.button.back-button, .ui.button.BackButton {
  color: ${primaryColor};
}

.BackButton.BackButton--variant-square-icon.BackButton--inverted .icon{
  color: ${primaryColor};
}

.footer .footer--text {
  background-color: ${primaryColor};
}

.card__read-more,
.block--cms-files .ui.card > .content > .header {
  color: ${primaryColor};
}

.inject-primary-color {
  color: ${primaryColor}!important;
}
button.FloatingChat__Bar {
  background-color: ${primaryColor};
}

.item--workshop>.icon.circle,
.item--workshop>.content>.ui.header { 
  color: ${primaryColor} !important; 
}
.ui.card.card--video >.content--custom-thumbnail  {
  color: ${primaryColor} ; 
}

.workshop--commentsBlock  .comment--input.ui.input {
  border-bottom-color: ${primaryColor};
}

.countdown .clock, .field--type-tag {
  background-color: ${primaryColor} ; 
  color:#FFF;
}
.countdown.inverted .clock {
  background-color:#FFF;
  color: ${primaryColor} ; 
}

.countdown-wrapper .countdown-item {
  color: ${primaryColor} ; 
}
.countdown-wrapper.inverted .countdown-item {
  color: #FFF; 
}

.countdown-wrapper .countdown-svg path {
  stroke: ${primaryColor};
}
.countdown-wrapper.inverted .countdown-svg path {
  stroke: #FFF; 
}

.Step__step.active {
  background-color: ${primaryColor};
}

.exhibitor-group__cards__card .ui.button.see-more {
  background-color: ${primaryColor}!important;
}

.list-details__items__item.is-active {
  background-color: ${primaryColor};
  color: white;
}

.block--ItemGrid .ui.items>.item>.content>.header {
  color: ${primaryColor};
}

.BackToTopArrow, .UserInfo__avatar--placeholder:not(.UserInfo__avatar--inverted) {
  background-color:${primaryColor};
}

.UserInfo__avatar--placeholder.UserInfo__avatar--inverted {
  color:${primaryColor};
}

.sidebar--menu__header .bars.icon, .sidebar--menu__container--header .icon.times,
.sidebar--menu__header .power.off.icon {
  color: ${primaryColor};
}

.tick-flip-panel {
  background-color: ${primaryColor} !important;
}

.flipClock .label {
  color: ${primaryColor};
}

.WorkshopModal__content .footer .previous .ui.button, .WorkshopModal__content  .footer .next .ui.button {
  background-color: ${primaryColor};
}

.WorkshopModal__content .footer .previous, .WorkshopModal__content  .footer .next  {
  color: ${primaryColor};
}

.WorkshopModal__content .footer .previous.disabled, .WorkshopModal__content  .footer .next.disabled  {
  color: ${alpha(primaryColor, 0.45)};
}

.SpeakerModal .SpeakerModal.SpeakerModal--header {
  background-color: ${primaryColor};  
  color: #FFF;
}

.item--languages-horizontal .lang a {
  color: #FFF;
}
.item--languages-horizontal .lang.is-active a,
.item--languages-horizontal .lang a:hover {
  color: ${primaryColor};
}

.go-back {
  color: ${primaryColor};
}

.ui.modal.UserPreferencesModalBlock .ui.card.preference .content .ui.button.follow.active {
  color: ${primaryColor}!important;
}

.DataItems__group--variant-accordion .Collapsible__trigger {
  background-color: ${primaryColor};
}

.FaqBlock .FaqBlock__question .title, .FaqBlock .FaqBlock__question i {
  color: ${primaryColor};
}



/* Custom CSS */
${Images.applyCDNToCSS(customCSS)}
${Images.applyCDNToCSS(designOverride?.customCSS || '')}
`,
          }}
        />
      </>
    );
  }
}

Styles.defaultProps = {
  designOverride: undefined,
};

Styles.propTypes = {
  design: designPropType.isRequired,
  designOverride: designPropType,
};

export default withDesign(Styles);
