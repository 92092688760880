/* eslint-disable @typescript-eslint/no-unused-vars */
import PropTypes from 'prop-types';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';

import './UserRegistrationModal.scss';

import { bem } from '../../../core/design/bem';
import { useForceUpdate, useLoginConfig } from '../../hooks';

// eslint-disable-next-line import/no-cycle
import LoginPasswordForm from '../LoginPasswordForm';
import { useScreenConfig } from '../../../config/screens.context';
import Blocks from '../../../components/Blocks';
import blockComponents from '../SideSection/blocks';

const css = bem('UserRegistrationModal');
const translationPrefix = 'login.registration.modal';

function UserRegistrationModal(props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const [showLogin, setShowLogin] = useState(false);
  const config = useLoginConfig();
  const forceUpdate = useForceUpdate();
  const registrationBlocks = get(config, ['ctaSection', 'blocks'], []);
  const { registrationUrl } = config;
  return (
    <>
      <Modal size="tiny" className={css().toString()} open onClose={onClose} closeIcon>
        <Modal.Header className={css('header').toString()}>
          {t(`${translationPrefix}.${!showLogin ? 'welcome' : 'sign-in'}`)}
        </Modal.Header>
        <Modal.Content className={css('content').toString()}>
          {showLogin ? (
            <LoginPasswordForm
              onLogin={() => {
                forceUpdate();
                onClose();
              }}
            />
          ) : (
            <>
              <Modal.Description className="please-sign-in">
                {t(`${translationPrefix}.please-sign-in`)}
              </Modal.Description>
              <Button
                primary
                basic
                className="sign-in"
                onClick={() => {
                  setShowLogin(true);
                }}
              >
                {t(`${translationPrefix}.sign-in`)}
              </Button>
              <Modal.Description className="no-account">
                {t(`${translationPrefix}.no-account`)}
                {registrationUrl ? (
                  <Link to={registrationUrl} className="sign-up">
                    {t(`${translationPrefix}.sign-up`)}
                  </Link>
                ) : (
                  <div style={{ marginTop: 10 }}>
                    <Blocks blocks={registrationBlocks} blockComponents={blockComponents} />
                  </div>
                )}
              </Modal.Description>
            </>
          )}
        </Modal.Content>
      </Modal>
    </>
  );
}

UserRegistrationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UserRegistrationModal;
