import keyBy from 'lodash/keyBy';
import trimEnd from 'lodash/trimEnd';
import values from 'lodash/values';
import { useMemo } from 'react';
import { useConfig } from './config.context';

export const useScreensConfig = () => {
  return useConfig()?.screens;
};

export function useScreenConfig(name) {
  const screenConfig = useScreensConfig() || {};
  // Backwards-compatible
  if (name === 'workshop') return screenConfig[name] ?? screenConfig.workshops?.[name] ?? {};
  return screenConfig[name] ?? {};
}

export function useAvailableScreenPaths() {
  const screens = useScreensConfig();
  return useMemo(() => {
    const allScreens = values(screens).filter((s) => s.path);
    return keyBy(allScreens, (s) => trimEnd(s.path, '/'));
  }, [screens]);
}
