/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header } from 'semantic-ui-react';
import VideoCard from '../video/VideoCard';
import BlockContainer from '../BlockContainer';
import { containerProptypes } from '../../propTypes';

const VideosBlock = (props) => {
  const { title, subtitle, files, container, videoProps, statsMode } = props;

  if (!files?.length) return null;
  // TODO: switch to BlockContainer
  return (
    <BlockContainer header={title} className="block--cms-files" {...container}>
      <Header as="h4" style={{ marginTop: 0 }}>
        {subtitle}
      </Header>
      <Card.Group>
        {files.map((video, index) => (
          <VideoCard
            key={video._id || index}
            video={video}
            videoProps={videoProps}
            statsMode={statsMode}
          />
        ))}
      </Card.Group>
    </BlockContainer>
  );
};

VideosBlock.defaultProps = {
  container: {},
  files: undefined,
  subtitle: undefined,
  videoProps: undefined,
  statsMode: undefined,
};
VideosBlock.propTypes = {
  container: containerProptypes,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  files: PropTypes.array,
  videoProps: PropTypes.object,
  statsMode: PropTypes.string,
};

export default VideosBlock;
