import React from 'react';

let nextId = 1;
const generateId = () => nextId++;

type ModalComponentType = (props: any) => JSX.Element;
type ModalProps = any;

type ModalState = {
  id: number;
  Modal: ModalComponentType;
  props: ModalProps;
};

type GlobalModalManagerState = {
  modals: ModalState[];
};

class GlobalModalManager extends React.Component<any, GlobalModalManagerState> {
  static instance: GlobalModalManager;

  static async open(ModalComponent: ModalComponentType, modalProps: ModalProps): Promise<any> {
    if (!GlobalModalManager.instance) return null;
    return new Promise((resolve) => {
      GlobalModalManager.instance.addModal(ModalComponent, modalProps, resolve);
    });
  }

  state: GlobalModalManagerState = {
    modals: [],
  };

  componentDidMount(): void {
    GlobalModalManager.instance = this;
  }

  addModal = (
    Modal: ModalComponentType,
    props: ModalProps,
    resolve: (data?: any) => void,
  ): void => {
    const id = generateId();
    const handleValidate = (data?: any) => {
      // Remove this modal
      resolve(data);
      this.setState(({ modals }) => ({ modals: modals.filter((m) => m.id !== id) }));
    };
    const modalProps = {
      ...props,
      onValidate: handleValidate,
      onCancel: () => handleValidate(),
    };
    this.setState(({ modals }) => ({ modals: [...modals, { id, Modal, props: modalProps }] }));
  };

  render(): JSX.Element {
    const { modals } = this.state;
    return (
      <>
        {modals.map(({ id, Modal, props }) => (
          <Modal key={id} {...props} open />
        ))}
      </>
    );
  }
}

export default GlobalModalManager;
