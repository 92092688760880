/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '../../shared/Store';
import { postJson } from '../../utils/fetch';

declare global {
  interface Window {
    __DATA__: {
      screens: Record<string, any>;
      endpoints: any;
    } & Record<string, any>; // TODO: type window.__DATA__
  }
}

const { endpoints } = window.__DATA__;

class NotificationsService {
  // eslint-disable-next-line class-methods-use-this
  async securePost(url: string, json: any) {
    return postJson(url, json, {
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
  }

  async registerToken(token: string) {
    if (!token) {
      console.warn('Missing token');
      return null;
    }
    return this.securePost(`${endpoints.platform}/notifications/_register`, { token });
  }

  async unregisterToken(token: string) {
    if (!token) {
      console.warn('Missing token');
      return null;
    }
    return this.securePost(`${endpoints.platform}/notifications/_unregister`, { token });
  }
}

export default new NotificationsService();
