/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react';
import GlobalModalManager from '../../GlobalModalManager';
import CTATilesBlock from '../CTATilesBlock';
import { CTATileBlockProps } from '../CTATilesBlock/CTATilesBlock';
import CmsPageModal from './CmsPageModal';

type CTAPageItem = CTATileBlockProps & { pageContent: { blocks: unknown[] } };
type CTAModalProps = {
  basic?: boolean;
};

type CTAPagesBlockProps = {
  _id: string;
  blocks: CTAPageItem[];
  modalProps?: CTAModalProps;
};

const CTAPagesBlock = ({ blocks, modalProps, ...props }: CTAPagesBlockProps): JSX.Element => {
  const handleClick = useCallback(
    (block: CTATileBlockProps) => {
      const { pageContent } = block as CTAPageItem;
      if (!pageContent) return;

      GlobalModalManager.open(CmsPageModal, { header: block.title, modalProps, page: pageContent });
    },
    [modalProps],
  );
  return <CTATilesBlock blocks={blocks} {...props} onClick={handleClick} />;
};

export default CTAPagesBlock;
