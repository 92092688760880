import React, { useState } from 'react';
import { Segment, Sidebar, List, Input } from 'semantic-ui-react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import get from 'lodash/get';
import { useSyncedCollectionWorkshopSessions } from '../../../workshop-session/store/workshopSessions.hooks';
import './workshopsmap.scss';
import InfoCard from './components/InfoCard';
import BlockContainer from '../../BlockContainer';

type WorkshopEntry = {
  _id: string;
  collection: string;
};

const WorkshopsMap = (props: { config: any; container: any }): false | JSX.Element => {
  const [selected, setSelected] = useState<string | undefined>();
  const [filter, setFilter] = useState('');
  console.log('props: ', props);
  const workshops: WorkshopEntry[] = useSyncedCollectionWorkshopSessions('workshops');

  const { config = {}, container } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAUUuKNMZYhn_tZudt8SwQu4XxmiELh4tE', // ToDo: A better place ?
  });

  const hideInfoCard = () => {
    setSelected(undefined);
  };

  const currentWorkshopInfoCard = workshops.find((w) => w._id === selected);

  const options = {
    boxStyle: { width: '200px', borderRadius: '2px' },
    enableEventPropagation: true,
  };

  const filteredList = workshops.filter(
    (w: any) =>
      w.title?.toLowerCase()?.includes(filter.toLowerCase()) ||
      w?.address?.city?.toLowerCase()?.includes(filter.toLowerCase()) ||
      w?.address?.region?.toLowerCase()?.includes(filter.toLowerCase()),
  );

  return (
    isLoaded && (
      <BlockContainer type="basic" {...container} className="block--workshops-map">
        <div className="workshops-map">
          <Sidebar.Pushable as={Segment} className="workshops-map__sidebar">
            <Sidebar animation="push" vertical visible>
              <div className="workshops-map__sidebar__content">
                {config?.sidebar?.title && <h1>{config.sidebar.title}</h1>}
                {config?.sidebar?.searchBar && (
                  <Input
                    icon="search"
                    value={filter}
                    onChange={(_e, { value }) => setFilter(value)}
                    fluid
                  />
                )}
                <List divided selection size="small">
                  {filteredList.map((w: any) => {
                    const active = w?._id === selected;
                    return (
                      <List.Item key={w._id} onClick={() => setSelected(w._id)} active={active}>
                        <List.Content>
                          <List.Header>{w.title}</List.Header>
                          <List.Description>
                            {w?.address?.city}, {w?.address?.region}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    );
                  })}
                </List>
              </div>
            </Sidebar>
          </Sidebar.Pushable>
          <GoogleMap
            zoom={config?.defaultZoom || 5}
            center={config?.defaultCenter || { lat: 47, lng: 2 }}
            mapContainerStyle={{
              width: '100%',
              height: '100%',
            }}
          >
            {currentWorkshopInfoCard && (
              <InfoCard
                options={options}
                workshop={currentWorkshopInfoCard}
                hideInfoCard={hideInfoCard}
                config={config}
              />
            )}
            {workshops.map((w: any) => {
              const coordinates = {
                lat: Number(get(w, config?.coordinates?.latKey)),
                lng: Number(get(w, config?.coordinates?.lngKey)),
              };

              return (
                <div>
                  <Marker
                    position={coordinates}
                    key={`marker${w._id}`}
                    onClick={() => setSelected(w._id)}
                    title={w._id}
                  />
                </div>
              );
            })}
          </GoogleMap>
        </div>
      </BlockContainer>
    )
  );
};

export default WorkshopsMap;
