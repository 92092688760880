/* eslint-disable consistent-return */
import { useRequest } from 'ahooks';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import throttle from 'lodash/throttle';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchAgenda } from '../../agenda/store/agenda.actions';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import { useConfig } from '../../config/config.context';
import { useScreenConfig } from '../../config/screens.context';
import { TrackingContext } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import store from '../../shared/Store';
import { showBookConflict } from '../../sponsors/blocks/BookAppointmentBlock/utils';
import { fetchRegistrations } from '../../store/actions';
import { getString } from '../../utils';
import { findOverlappingEvent } from '../../utils/agendaUtils';
import { sweetConfirmAlert } from '../../utils/popupUtils';
import { useStoreCollectionSync } from '../../utils/useVersionSync';
// eslint-disable-next-line import/no-cycle
import { useSyncedWorkshops } from '../../workshops/store/workshops.hooks';
import workshopSessionService from '../services/workshop-session.service';
import { fetchWorkshopSessions, throttledFetchWorkshopSessions } from './workshopSessions.actions';
import {
  formatSessionErrors,
  showError,
  showSessionErrors,
  showSessionRegistrationAlert,
} from '../sessionRegistrationUtils';

const translationPrefix = 'workshop-sessions.registration';

export function useWorkshopSessions(collection, workshopId) {
  return useRequest(() => workshopSessionService.fetchWorkshopSessions(collection, workshopId), {
    formatResult: (workshopSessions) => orderBy(workshopSessions, 'startDate', 'asc'),
    cacheKey: `workshops/${collection}/${workshopId}/sessions`,
    cacheTime: 60 * 1000,
  });
}

const throttleWorkshopSessions = throttle(
  () => store.reduxStore.dispatch(fetchWorkshopSessions()),
  500,
  { trailing: false },
);

export function useApecSessionsSync() {
  const config = useConfig();
  useEffect(() => {
    if (config.apec) {
      throttleWorkshopSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(config.apec)]);
}

export function useWorkshopWithSessions() {
  useApecSessionsSync();
  const workshops = useSyncedWorkshops();
  const workshopSessions = orderBy(
    useSelector((state) => state.workshopSessions.workshopSessions),
    'startDate',
    'asc',
  );

  return useMemo(() => {
    const sessionsByWorkshopId = groupBy(workshopSessions, 'workshopId');
    return workshops.map((workshop) => {
      const { _id: workshopId } = workshop;
      const sessions = get(sessionsByWorkshopId, workshopId) || [];
      const nextSession = sessions.find(
        ({ quota, usersCount }) => !!quota && quota - usersCount !== 0,
      );
      return {
        ...workshop,
        sessionCount: sessions.length,
        nextSession,
      };
    });
  }, [workshops, workshopSessions]);
}

export async function registerWorkshopSession(
  session,
  agenda,
  { trackEvent, setLoading, allowOverlappingSessions } = {},
  sessionsToUnregister,
) {
  try {
    setLoading(true);
    const { collection, workshopId, _id: sessionId } = session;
    const overlappingEvent = findOverlappingEvent(agenda, session);

    if (overlappingEvent && !allowOverlappingSessions) {
      await showBookConflict(overlappingEvent);
      return;
    }

    const res = await workshopSessionService.registerWorkshopSession(
      collection,
      workshopId,
      sessionId,
      sessionsToUnregister,
    );
    if (res.success) {
      trackEvent(eventTags.WORKSHOP_REGISTER, {
        userId: store.userId,
        session,
        item: session,
      });
      store.reduxStore.dispatch(fetchRegistrations());
      store.reduxStore.dispatch(fetchWorkshopSessions());
      store.reduxStore.dispatch(fetchAgenda());
      toast(getString(`${translationPrefix}.successful-registration`));
      return true;
    }
    if (res.errors) {
      store.reduxStore.dispatch(fetchRegistrations());
      const { code } = res.errors[0];
      showError(code);
    }
    return false;
  } catch (error) {
    showError('ERROR');
    return false;
  } finally {
    setLoading(false);
  }
}

export async function unregisterWorkshopSession(session, { setLoading, trackEvent }) {
  const { collection, workshopId } = session;
  const sessionId = session.sessionId || session._id; // Handle both cases
  const confirm = await sweetConfirmAlert({
    title: getString(`${translationPrefix}.unregister-confirm`, {
      name: session?.title,
    }),
    confirmButtonText: getString(`alert.confirm`),
    cancelButtonText: getString(`alert.cancel`),
  });
  if (confirm) {
    trackEvent(eventTags.WORKSHOP_UNREGISTER, {
      userId: store.userId,
      session,
      item: session,
    });
    if (setLoading) setLoading(true);
    await workshopSessionService.unregisterFromWorkshopSession(collection, workshopId, sessionId);
    store.reduxStore.dispatch(fetchRegistrations());
    store.reduxStore.dispatch(fetchWorkshopSessions());
    store.reduxStore.dispatch(fetchAgenda());
    if (setLoading) setLoading(false);
    toast(getString(`${translationPrefix}.successful-cancel`, { name: session.title }));
    return true;
  }
  return false;
}

export const switchWorkshopSessionRegistration = async (
  actualSession,
  newSession,
  { setLoading, trackEvent },
) => {
  const { collection, workshopId } = newSession;
  const sessionId = newSession.sessionId || newSession._id;
  const res = await workshopSessionService.switchWorkshopSession(collection, workshopId, sessionId);
  if (res.success) {
    trackEvent(eventTags.WORKSHOP_REGISTER, {
      userId: store.userId,
      newSession,
      item: newSession,
    });
    setLoading?.(true);
    store.reduxStore.dispatch(fetchRegistrations());
    store.reduxStore.dispatch(fetchWorkshopSessions());
    store.reduxStore.dispatch(fetchAgenda());
    setLoading?.(false);
    toast(getString(`${translationPrefix}.successful-registration`));
    return true;
  }
};

export function useWorkshopSessionRegistration(session = {}, options) {
  const agenda = useSelector((state) => userAgenda(state));
  const [loading, setLoading] = useState(false);
  const screenConfig = useScreenConfig(session?.collection);
  const { allowOverlappingSessions = false } = options ?? screenConfig ?? {};
  const { trackEvent } = useContext(TrackingContext);
  const { usersCount, quota } = session;
  const isWorkshopSessionFull = quota ? quota === usersCount : false;

  return {
    isWorkshopSessionFull,
    registerWorkshopSession: async (sessionsToUnregister) => {
      return registerWorkshopSession(
        session,
        agenda,
        {
          allowOverlappingSessions,
          trackEvent,
          setLoading,
        },
        sessionsToUnregister,
      );
    },
    loading,
    unregisterWorkshopSession: async () =>
      unregisterWorkshopSession(session, { trackEvent, setLoading, allowOverlappingSessions }),
  };
}

export async function registerMultipleWorkshopSessions(sessions, { setLoading, config = {} }) {
  try {
    const confirm = await sweetConfirmAlert({
      title: getString(`${translationPrefix}.multi-registation`, { count: sessions.length }),
      confirmButtonText: getString(`alert.confirm`),
      cancelButtonText: getString(`alert.cancel`),
    });

    if (confirm) {
      setLoading(true);
      const res = await workshopSessionService.registerMultipleWorkshopSessions(sessions);

      store.reduxStore.dispatch(fetchRegistrations());
      store.reduxStore.dispatch(fetchWorkshopSessions());
      store.reduxStore.dispatch(fetchAgenda());

      const errors = formatSessionErrors(res, sessions);

      if (errors.length > 0) {
        showSessionErrors(errors);
        return { errors };
      }
      showSessionRegistrationAlert(config);

      return { success: true };
    }
    return false;
  } catch (error) {
    showError('ERROR');
    return false;
  } finally {
    setLoading(false);
  }
}

export function useMultiWorkshopRegistration(sessions, { config }) {
  const [loading, setLoading] = useState(false);
  return {
    registerManySessions: async () =>
      registerMultipleWorkshopSessions(sessions, { setLoading, config }),
    loading,
  };
}

export function useSyncedWorkshopSessions() {
  useStoreCollectionSync({
    resourceType: 'workshopSessions',
    fetcher: throttledFetchWorkshopSessions,
  });
  return useSelector((state) => state.workshopSessions.workshopSessions);
}

export function useSyncedCollectionWorkshopSessions(collection) {
  useStoreCollectionSync({
    resourceType: 'workshopSessions',
    fetcher: throttledFetchWorkshopSessions,
  });
  const sessions = useSelector((state) => state.workshopSessions.workshopSessions);
  return useMemo(() => sessions.filter((w) => w.collection === collection), [sessions, collection]);
}

export function useGroupWorkshopsWithSessions(workshops, sessions) {
  return useMemo(() => {
    if (!sessions) return workshops;

    const sessionsByWorkshop = groupBy(sessions, 'workshopId');

    return workshops.map((workshop) => {
      const workshopSessions = sessionsByWorkshop[workshop._id] || [];
      const usersCount = sumBy(workshopSessions, 'usersCount') || 0;
      const quota = sumBy(workshopSessions, 'quota');
      return {
        ...workshop,
        usersCount,
        quota,
        sessions: workshopSessions,
      };
    });
  }, [workshops, sessions]);
}
