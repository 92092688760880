import get from 'lodash/get';
import matches from 'lodash/matches';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import en from '../translations/en.json';
import fr from '../translations/fr.json';
import de from '../translations/de.json';
import es from '../translations/es.json';
import nl from '../translations/nl.json';
import { replaceValues } from './stringUtils';

const translations = { fr, en, es, de, nl };
const { strings = {} } = window.__DATA__ || {};

function lang() {
  return (window.__DATA__ || {}).lang || 'fr';
}

export function getParameterValueByName(name, url) {
  // eslint-disable-next-line no-undef, no-param-reassign
  if (!url) url = window.location.href;
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[[\]]/g, '\\$&');
  const retriveNameAndValueRegex = `[?&]${name}(=([^&#]*)|&|#|$)`;
  const regex = new RegExp(retriveNameAndValueRegex);
  const results = regex.exec(url);
  if (!results) return null;
  const value = results[2];
  if (!value) return '';
  return decodeURIComponent(value.replace(/\+/g, ' '));
}

function getTranslations(lng) {
  if (lng in translations) return translations[lng];

  const hasParent = lng.indexOf('-') !== -1;
  if (hasParent) {
    return translations[lng.split('-')[0]] || fr;
  }
  return fr;
}

export function getString(key, values) {
  const text = get(strings, key) || get(getTranslations(lang()), key);
  if (!values) return text;

  // Replace values
  return replaceValues(text, values).trim();
}

export function getStrings(key) {
  const defaultObj = get(getTranslations(lang()), key);
  const customObj = get(strings, key);
  return { ...defaultObj, ...customObj };
}

/**
 * Check for pretty labels in the strings
 * @param {*} entity Entity data (json)
 * @param {*} type Type (workshop, sponsor, etc.)
 * @param {*} key Key to map (category, collection, etc.)
 */
export function getEntityString(entity, type, key) {
  const value = entity[key];
  if (!value) return '';
  return getString(`${type}.${key}.${value}`) || value;
}

export function throttleDispatchFetch(func, throttleTime = 30000) {
  return throttle(func, throttleTime, {
    leading: true,
    trailing: false,
  });
}

export function matchDefinedValues(object) {
  return matches(pickBy(object, (v) => v));
}
