/* eslint-disable prettier/prettier */
import { CmsBlocks } from '../../cms/CmsScreen';
import BrowserCheckBlock from '../../components/cms/BrowserCheckBlock';
import HtmlBlock from '../../components/cms/HtmlBlock';
import ImageBlock from '../../components/cms/ImageBlock';
import ItemCTATilesBlock from '../../components/cms/ItemCTATilesBlock';
import CTABlock from '../../sidebar/blocks/CTABlock';
import FilesBlock from '../../sidebar/blocks/FilesBlock';
import ContactsBlock from '../../sidebar/blocks/ContactsBlock';
import CollectionAndCategoryWorkshops from './CollectionAndCategoryWorkshops';
import FeedbackPopupBlock from './FeedbackPopupBlock';
import ItemHtmlBlock from './ItemHtmlBlock';
import ItemImageBlock from './ItemImageBlock';
import ItemGalleryBlock from './ItemGalleryBlock';
import WorkshopReplayBlock from './ReplayBlock';
import WorkshopBlogsBlock from './WorkshopBlogsBlock';
import WorkshopCommentsBlock from './WorkshopCommentsBlock';
import WorkshopDetailsBlock from './WorkshopDetailsBlock';
import WorkshopFeebdackBlock from './WorkshopFeedbackBlock';
import WorkshopLiveBlock from './WorkshopLiveBlock';
import WorkshopQuestionsBlock from './WorkshopQuestionsBlock';
import WorkshopRegistrationBlock from './WorkshopRegistrationBlock';
import WorkshopSeeAlsoBlock from './WorkshopSeeAlsoBlock';
import WorkshopSpeakersBlock from './WorkshopSpeakersBlock';
import WorkshopSponsorsBlock from './WorkshopSponsorsBlock';
import WorkshopVisioRoomBlock from './WorkshopVisioBlock';
import NextSessionBlock from './NextSessionBlock';
import WorkshopActionsBlock from "../../sidebar/blocks/WorkshopActionsBlock";

export const mainBlockComponents = {
  'browserCheck': BrowserCheckBlock,
  'details': WorkshopDetailsBlock,
  'html': ItemHtmlBlock,
  'cms/html': HtmlBlock,
  'gallery': ItemGalleryBlock,
  'image': ItemImageBlock,
  'live': WorkshopLiveBlock,
  'replay': WorkshopReplayBlock,
  'speakers': WorkshopSpeakersBlock,
  'sponsors': WorkshopSponsorsBlock,
  'feedbackPopup': FeedbackPopupBlock,
  'comments': WorkshopCommentsBlock,
  'feedbacks': WorkshopFeebdackBlock,
  'blogs': WorkshopBlogsBlock,
  'nextSession': NextSessionBlock,
  'cms/image': ImageBlock,
  'item/ctaTiles': ItemCTATilesBlock,
  'collectionAndCategoryWorkshops': CollectionAndCategoryWorkshops,
};

export const sidebarBlockComponents = {
  actionsBlock: WorkshopActionsBlock,
  registrationBlock: WorkshopRegistrationBlock,
  files: FilesBlock,
  seeAlso: WorkshopSeeAlsoBlock,
  cms: CmsBlocks,
  questions: WorkshopQuestionsBlock,
  visioRoom: WorkshopVisioRoomBlock,
  collectionAndCategoryWorkshops: CollectionAndCategoryWorkshops,
  feedbacks: WorkshopFeebdackBlock,
  nextSession: NextSessionBlock,
  sponsors: WorkshopSponsorsBlock,
  cta: CTABlock,
  contacts: ContactsBlock,
};
