import React from 'react';
import { Button, Icon, Header } from 'semantic-ui-react';
import { InfoWindow } from '@react-google-maps/api';
import get from 'lodash/get';
import EntityLink from '../../../EntityLink';
import { replaceUriValues, replaceValues } from '../../../../utils/stringUtils';
import { trackEvent } from '../../../../core/trackers';
import { eventTags } from '../../../../core/trackers/events';

const InfoCard = (props: {
  options: any;
  workshop: any;
  config: any;
  hideInfoCard: () => void;
}): JSX.Element => {
  const { options, workshop = {}, hideInfoCard, config = {} } = props;
  const center = {
    lat: Number(get(workshop, config?.coordinates?.latKey)),
    lng: Number(get(workshop, config?.coordinates?.lngKey)),
  };
  return (
    <InfoWindow options={options} position={center} onCloseClick={hideInfoCard}>
      <div className="info-window">
        <Button onClick={hideInfoCard} className="close">
          <Icon name="close" />
        </Button>
        <div style={{ background: '#F0F0F0', padding: 4 }}>
          <Header as="h2">{workshop.title}</Header>
          {config?.infos?.map((info: { label: string; field: string; uri: string }) => {
            const { label, field, uri } = info;
            const content = get(workshop, field);
            const correctedUri = (): string => {
              if (uri?.indexOf(':') === -1) {
                return replaceUriValues(uri, workshop);
              }
              return replaceValues(uri, workshop);
            };
            return (
              content && (
                <div
                  key={`info--${workshop._id}--${info.field}`}
                  className={`info info--${info.field.split('.').join('')}`}
                >
                  <p className="label">{label}</p>
                  <p className="content">
                    {uri ? (
                      <a
                        href={correctedUri()}
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={() => trackEvent(eventTags.ITEM_ACTION_CLICK, { field, workshop })}
                      >
                        {content}
                      </a>
                    ) : (
                      <>{content}</>
                    )}
                  </p>
                </div>
              )
            );
          })}
        </div>
        <Button primary size="small" as={EntityLink} entity={workshop}>
          Voir plus
        </Button>
      </div>
    </InfoWindow>
  );
};

export default InfoCard;
