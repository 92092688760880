import { format, parseISO } from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

import { dateProptypes } from '../propTypes';

import { dateFNSLocale, forceTimezone, momentTz } from '../utils/date';
import WorkshopDate from './workshops/WorkshopDate';

export const TimeDisplay = ({ startDate, endDate, timezone }) => {
  const { t } = useTranslation();
  if (forceTimezone()) {
    return (
      <p className="time-display">
        <span className="start-date">{momentTz(parseISO(startDate)).format('LT')}</span>
        <span className="end-date"> - {momentTz(parseISO(endDate)).format('LT')}</span>
        <span className="timezone">{momentTz(parseISO(startDate)).format(' z')}</span>
      </p>
    );
  }
  if (timezone) {
    const formattedStartDate = moment(parseISO(startDate)).tz(timezone);
    const formattedEndDate = moment(parseISO(endDate)).tz(timezone);
    return (
      <span className="datetime">
        {t('workshops.time', {
          timezone,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        })}
      </span>
    );
  }
  return (
    <p className="time-display">
      <WorkshopDate startDate={startDate} endDate={endDate} showEndTime showDay={false} />
    </p>
  );
};

TimeDisplay.defaultProps = {
  endDate: undefined,
  startDate: undefined,
  timezone: undefined,
};

TimeDisplay.propTypes = {
  endDate: dateProptypes,
  startDate: dateProptypes,
  timezone: PropTypes.string,
};

export const DateDisplay = ({ startDate }) => {
  const dateOptions = { locale: dateFNSLocale() };
  return (
    <p className="date-display" style={{ marginBottom: 0 }}>
      <Icon name="calendar outline alternate" />
      {format(parseISO(startDate), 'PPPP', dateOptions)}
    </p>
  );
};

DateDisplay.defaultProps = {
  startDate: undefined,
};

DateDisplay.propTypes = {
  startDate: dateProptypes,
};

export const DateTimeDisplay = ({ startDate, endDate, dateConfig }) => {
  if (!startDate) return null;
  return (
    <div className="date-time-display">
      <DateDisplay startDate={startDate} endDate={endDate} />
      {endDate && <TimeDisplay startDate={startDate} endDate={endDate} dateConfig={dateConfig} />}
    </div>
  );
};

DateTimeDisplay.defaultProps = {
  dateConfig: {},
  endDate: undefined,
  startDate: undefined,
};

DateTimeDisplay.propTypes = {
  dateConfig: PropTypes.object,
  endDate: dateProptypes,
  startDate: dateProptypes,
};
